export const hrdTypesList = [
  {
    id: 1,
    name: "Blank",
    value: "Blank",
  },
  {
    id: 2,
    name: "Funtional Field Assessment",
    value: "Functional",
  },
  {
    id: 3,
    name: "Rule & Regulation Assessment",
    value: "Rule",
  },
];
